import React from "react";
import { MDBCard } from "mdb-react-ui-kit";
import RefLink from "../RefLink/RefLink";
import SignalInfoPopover from "../SignalInfo/SignalInfo";

const HeaderContent = ({ cardTitle }) => (
  <div className="card-header p-3 pt-4 pb-3 border-0">
    <div className="d-flex justify-content-between">
      <h5 className="card-title fw-normal">{cardTitle}</h5>
      {/* <div className="d-flex align-items-center text-primary">
        <SignalInfoPopover signal={cardTitle} placement="left" />
      </div> */}
    </div>
  </div>
);

const ReferenceList = ({
  title,
  references,
  isDataAvailable,
  renderFunction,
  isLastElement,
}) => (
  <div className={`mt-1 ${isLastElement ? "mb-1" : "mb-3"}`}>
    <p className="fw-bold mb-1">{title}:</p>
    {references?.length
      ? references.map(renderFunction)
      : isDataAvailable
      ? "None found"
      : "N/A"}
  </div>
);

const Statistic = ({ label, value }) => (
  <div>
    <span className="fw-normal">{label}:</span>
    <span className="ms-1">{value ?? "-"}</span>
  </div>
);

const ReferencesTab = ({
  refIdentifiers,
  retractionWatch,
  referencesRetractions,
  cactusTool,
}) => {
  const { extracted, invalid, parsed, unidentified } = refIdentifiers || {};
  const { details: mentions, retracted: retractedRefs } =
    referencesRetractions || {};

  const cardColorClass = (conditions) =>
    conditions.some((cond) => cond?.length) ? "bg-danger" : "bg-success";
  const renderInvalidItem = (item, index) => (
    <React.Fragment key={item.DOI}>
      {index + 1}. {item.DOI} '{item.title}'<br />
    </React.Fragment>
  );
  const renderRetractedItem = (item, index) => (
    <RefLink
      item={item}
      pubpeer={true}
      index={index}
      key={index}
      textColor={"white"}
    />
  );
  const renderMentionItem = ({ doi, comments }, index) => (
    <RefLink
      item={doi}
      pubpeer={true}
      index={index}
      key={index}
      comments={comments}
      textColor={"white"}
    />
  );

  const renderCactusAuthorship = ({ result, checkLabel }, index) => {
    return (
      <div>
        {checkLabel} : {result}
      </div>
    );
  };

  const renderCactusReferences = ({ checkLabel, result }, index) => {
    return (
      <div>
        {checkLabel} : {result}
      </div>
    );
  };

  return (
    <>
      <MDBCard className={`mt-4 bg-opacity-25 ${cardColorClass([invalid])}`}>
        <HeaderContent cardTitle="DOI analysis" />
        <div className={`card-body bg-white bg-opacity-25 p-3`}>
          <ReferenceList
            title="Listed DOIs that do not exist"
            references={invalid}
            isDataAvailable={true}
            renderFunction={renderInvalidItem}
          />
          <ReferenceList
            title="Non retrieved reference titles"
            references={unidentified}
            isDataAvailable={unidentified}
            renderFunction={renderInvalidItem}
            isLastElement={true}
          />
        </div>
        <div className={"card-footer border-0 px-3 py-4"}>
          <Statistic
            label="Total number of references listed in manuscript"
            value={parsed}
          />
          <Statistic
            label="From which DOIs were listed or could be retrieved"
            value={extracted}
          />
          <Statistic
            label="Number of listed DOIs that do not exist"
            value={invalid?.length}
          />
          <Statistic
            label="Non retrieved references"
            value={unidentified?.length}
          />
        </div>
      </MDBCard>

      <MDBCard
        className={`mt-4 bg-opacity-25 ${cardColorClass([retractionWatch])}`}
      >
        <HeaderContent cardTitle="Retraction Watch" />
        <div className={"card-body p-3 bg-white bg-opacity-25"}>
          <ReferenceList
            title="Mentioned references"
            references={retractionWatch}
            isDataAvailable={retractionWatch}
            renderFunction={renderInvalidItem}
            isLastElement={true}
          />
        </div>
        <div className={"card-footer border-0 px-3 py-4"}>
          <Statistic
            label="Total number of references listed in manuscript"
            value={parsed}
          />
          <Statistic
            label="From which DOIs were listed or could be retrieved"
            value={extracted}
          />
        </div>
      </MDBCard>

      <MDBCard
        className={`mt-4 bg-opacity-25 ${cardColorClass([
          mentions,
          retractedRefs,
        ])}`}
      >
        <HeaderContent cardTitle="PubPeer mentions" />
        <div className={"card-body p-3 bg-white bg-opacity-25"}>
          <ReferenceList
            title="References retracted"
            references={retractedRefs}
            isDataAvailable={referencesRetractions}
            renderFunction={renderRetractedItem}
          />
          <ReferenceList
            title="Mention"
            references={mentions}
            isDataAvailable={referencesRetractions}
            renderFunction={renderMentionItem}
            isLastElement={true}
          />
        </div>
        <div className={"card-footer border-0 px-3 py-4"}>
          <Statistic
            label="Total number of references listed in manuscript"
            value={parsed}
          />
          <Statistic
            label="From which DOIs were listed or could be retrieved"
            value={extracted}
          />
        </div>
      </MDBCard>
    </>
  );
};

export default ReferencesTab;
