import React from "react";
import { MDBTypography } from "mdb-react-ui-kit";
import ServiceDisabledPopover from "../SignalInfo/ServiceDisabledPopover";
import ServiceErrorPopover from "../SignalInfo/ServiceErrorPopover";
import ServiceProcessingPopover from "../SignalInfo/ServiceProcessingPopover";

const GREY_COLOR = "#9fa6b2";

const DataField = ({ title, items, viewDetails }) => {
  return (
    <div className="data_field">
      <div
        className="d-flex align-items-center mb-2"
        style={{ color: items.length ? "inherit" : GREY_COLOR }}
      >
        {title}
      </div>
      {items.map((item, index) => {
        const isServiceNotEnabled = item?.value === "n/a";
        const isServiceUnavailable = !isServiceNotEnabled && item.isUnavailable;
        const processingMessage = item?.processingMessage;
        return (
          <div key={index} className="d-flex align-items-center">
            <div
              className="d-flex"
              style={{ cursor: "pointer" }}
              onClick={viewDetails}
            >
              <MDBTypography
                variant="p"
                className="mb-1 text-primary"
                key={index}
              >
                <small
                  style={{
                    color:
                      isServiceNotEnabled || isServiceUnavailable
                        ? GREY_COLOR
                        : item?.labelColor,
                  }}
                >
                  {item.label}
                  {item?.value && !isServiceNotEnabled ? (
                    <span className="ms-1" style={{ color: "indigo" }}>
                      {item.value}
                    </span>
                  ) : null}
                </small>
              </MDBTypography>
              {item?.labelColor ? (
                <span>
                  <i
                    style={{ color: item?.labelColor }}
                    className="fa fa-exclamation-circle ms-1"
                  ></i>
                </span>
              ) : null}
              {isServiceNotEnabled && <ServiceDisabledPopover />}
              {isServiceUnavailable && <ServiceErrorPopover />}
              {!!processingMessage && (
                <ServiceProcessingPopover popoverBody={processingMessage} />
              )}
            </div>
            {item.popover}
          </div>
        );
      })}
    </div>
  );
};

export default DataField;
