import React from "react";
import styled from "styled-components";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModalDialog,
  MDBModalContent,
  MDBTypography,
} from "mdb-react-ui-kit";
import { MDBSwitch } from "mdb-react-ui-kit";
import { Formik, Form, useField } from 'formik';
import { nanoid } from 'nanoid';

const MDBSwitchWrapper = styled.div`
    .form-check-input[type=checkbox]:checked { background-color: #54b4d3; }
    .form-switch .form-check-input:checked[type=checkbox]:after { background-color: #54b4d3; }
`;

const SwitchInput = ({ label, ...props }) => {
  const [field] = useField({ type: "checkbox", ...props });
  return (
    <MDBSwitchWrapper>
      <MDBSwitch {...field} id={nanoid()} label={label} />
    </MDBSwitchWrapper>
  );
};

const FilterSelectorModal = ({ isOpen, setIsOpen, filter, setFilter, components }) => {
  const handleClose = () => setIsOpen(false);

  return (
    <MDBModal show={isOpen} setShow={setIsOpen} tabIndex="-1">
      <MDBModalDialog size="md">
        <MDBModalContent>
          <MDBModalHeader>
            Filter by Signals
            <MDBBtn className="btn-close" color="none" onClick={handleClose} />
          </MDBModalHeader>
          <MDBModalBody className="mt-2">
            <Formik
              initialValues={{
                checked: filter ? filter.split(",") : [],
              }}
              onSubmit={(values) => {
                values.checked.length
                  ? setFilter(values.checked.join(","))
                  : setFilter("");
                handleClose();
              }}
              onReset={() => {
                setFilter("");
                handleClose();
              }}
            >
              {({ handleReset }) => {
                return (
                  <Form>
                    <div className="mb-4">
                      <MDBTypography tag="strong">Reference analysis</MDBTypography>
                      {components.doiResolutions && (
                        <SwitchInput name="checked" value="da" label="DOI analysis" />
                      )}
                      {components.retractionWatch && (
                        <SwitchInput name="checked" value="rw" label="Retraction watch" />
                      )}
                      <MDBTypography tag="strong">PubPeer</MDBTypography>
                      {components.feetOfClayDetector && (
                        <SwitchInput name="checked" value="pm" label="PubPeer hits" />
                      )}
                      <MDBTypography tag="strong">ClearSkies Papermill Alarm</MDBTypography>
                      {components.clearSkiesStatus && (
                        <SwitchInput name="checked" value="ps" label="Papermill similarity" />
                      )}
                      <MDBTypography tag="strong">Text analysis</MDBTypography>
                      {components.torturedPhrases && (
                        <SwitchInput name="checked" value="tph" label="Tortured phrases" />
                      )}
                      {components.unnaturalTextDetector && (
                        <SwitchInput name="checked" value="ut" label="Unnatural text" />
                      )}
                      <MDBTypography tag="strong">Watchlist</MDBTypography>
                      {components.watchlistFakeAffiliationNames && (
                        <SwitchInput name="checked" value="aff" label="Fake affiliation names" />
                      )}
                      {components.watchlistFakeNamesOrEmailAddresses && (
                        <SwitchInput name="checked" value="idd" label="Fake names or e-mail addresses" />
                      )}
                      {components.watchlistFakeEmailDomains && (
                        <SwitchInput name="checked" value="em" label="Fake e-mail domains" />
                      )}
                      {components.watchlistDisposableEmailDomains && (
                        <SwitchInput name="checked" value="ded" label="Disposable e-mail domains" />
                      )}
                      {components.watchlistBadActors && (
                        <SwitchInput name="checked" value="ba" label="Bad actors" />
                      )}
                      {components.watchlistSuspectArticles && (
                        <SwitchInput name="checked" value="sa" label="Suspect articles" />
                      )}
                      {components.watchlistManuscriptsOfferedForSale && (
                        <SwitchInput name="checked" value="mfs" label="Manuscripts offered for sale" />
                      )}
                      {components.watchlistMetadataSuspects && (
                        <SwitchInput name="checked" value="ms" label="Metadata suspects" />
                      )}
                      {components.watchlistChatGPTOutput && (
                        <SwitchInput name="checked" value="chatr" label="GenAI response" />
                      )}
                    </div>
                    <MDBModalFooter className="mt-3 ">
                      <MDBBtn size="md" type="submit" >
                        Set
                      </MDBBtn>
                      <MDBBtn
                        size="md"
                        color="danger"
                        type="submit"
                        onClick={handleReset}
                      >
                        Reset
                      </MDBBtn>
                    </MDBModalFooter>
                  </Form>
                );
              }}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default FilterSelectorModal;
