import React from "react";
import { MDBCard } from "mdb-react-ui-kit";
import SignalInfoPopover from "../../SignalInfo/SignalInfo";
import CustomPopover from "../../SignalInfo/CustomPopover";

const CactusPopover = ({ popoverHeader, popoverBody }) => {
  return (
    <CustomPopover
      popoverHeader={popoverHeader}
      popoverBody={popoverBody}
      placement={"auto"}
    >
      <span>
        <i className="fa fa-info-circle fa-stack-1x info_circle ms-1"></i>
      </span>
    </CustomPopover>
  );
};

const HeaderContent = ({ cardTitle }) => (
  <div className="card-header p-3 pt-4 pb-3 border-0">
    <div className="d-flex justify-content-between">
      <h5 className="card-title fw-normal">{cardTitle}</h5>
      {/* <div className="d-flex align-items-center text-primary">
        <SignalInfoPopover signal={cardTitle} placement="left" />
      </div> */}
    </div>
  </div>
);

const ReferenceList = ({
  title,
  references,
  isDataAvailable,
  renderFunction,
  isLastElement,
}) => (
  <div className={`mt-1 ${isLastElement ? "mb-1" : "mb-3"}`}>
    <p className="fw-bold mb-1">{title}:</p>
    {references?.length
      ? references.map(renderFunction)
      : isDataAvailable
      ? "None found"
      : "N/A"}
  </div>
);

const AUTHORSHIP_EXT_KEY_LABELS = {
  author_affiliation_relevance: {
    label: "Author affiliation relevance",
    popoverText: "’Authors’ affiliations from more 3 or more countries.",
  },
  author_location: {
    label: "Author geographical distribution",
    popoverText:
      "Affiliation relevance to the field of study discussed in the manuscript.",
  },
};

const REFERENCE_EXT_KEY_LABELS = {
  citation_cartel_reference: {
    label: "Citation cartel",
    popoverText:
      "Excessive citations to one person or group of people from the same lab.",
  },
  self_citation_reference: {
    label: "Use of self-citation",
    popoverText:
      "Excessive citation to papers by the authors of the manuscript.",
  },
  age_of_reference: {
    label: "Age of reference",
    popoverText: "Too many old references cited.",
  },
  reference_cited: {
    label: "Reference Citations",
    popoverText: "Listed references not cited in the manuscript.",
  },
  unrelated_reference: {
    label: "Unrelated references",
    popoverText:
      "List of references that are not relevant to the field of study discussed in the manuscript.",
  },
};

const CactusPilotTab = ({ cactusTool }) => {
  const cardColorClass = (conditions) =>
    conditions.some((cond) => cond?.length) ? "bg-danger" : "bg-success";

  const renderCactusAuthorship = ({ result, extKey, checkLabel }, index) => {
    const { label, popoverText } =
      AUTHORSHIP_EXT_KEY_LABELS[extKey] || checkLabel;

    return (
      <div key={index} className="d-flex">
        {label} : {result}
        <CactusPopover popoverHeader={label} popoverBody={popoverText} />
      </div>
    );
  };

  const renderCactusReferences = ({ extKey, checkLabel, result }, index) => {
    const { label, popoverText } =
      REFERENCE_EXT_KEY_LABELS[extKey] || checkLabel;
    return (
      <div key={index} className="d-flex">
        {label} : {result}
        <CactusPopover popoverHeader={label} popoverBody={popoverText} />
      </div>
    );
  };

  return (
    <MDBCard
      className={`mt-4 bg-opacity-25 ${cardColorClass([
        cactusTool?.authorship,
        cactusTool?.references,
      ])}`}
    >
      <HeaderContent cardTitle="Cactus pilot" />
      <div className={"card-body p-3 bg-white bg-opacity-25"}>
        <ReferenceList
          title="Authorship-related issues"
          references={cactusTool?.authorship}
          isDataAvailable={cactusTool?.authorship}
          renderFunction={renderCactusAuthorship}
        />
        <ReferenceList
          title="Reference-related issues"
          references={cactusTool?.references}
          isDataAvailable={cactusTool?.references}
          renderFunction={renderCactusReferences}
          isLastElement={true}
        />
      </div>
    </MDBCard>
  );
};

export default CactusPilotTab;
