import { axiosInstance } from './axiosSetup';
import axios from 'axios';
import { handleErrors } from './handleErrors';
import { isNumeric, isObject, isEmptyObject } from './helpers';
import { decode } from 'html-entities';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const updatePassword = async (userId, data) => {
    return axiosInstance
        .patch(`admin/user/${userId}`, data)
};

export const getComponents = async () => {
    return axiosInstance.get('/user')
};

export const getEvents = async (offset, perPage, search, filter) => {
    return axiosInstance
        .get(`papermill/events?startrow=${offset}&maxrows=${perPage}&searchq=${encodeURIComponent(search)}&filter=${filter}`)
};

export const uploadFile = async (file) => {
    try {
        const response = await axiosInstance.post('papermill/initiate', { document: file.name });
        const { s3PutObjectUrl } = response.data;
        await axios.put(s3PutObjectUrl, file);
        return true;
    } catch (error) {
        handleErrors(error);
        throw error;
    }
};

export const exportEvents = async () => {
    return axiosInstance
        .get('papermill/events/export')
};

export const deleteEvent = async (id) => {
    return axiosInstance
        .delete(`papermill/event/${id}`)
};

export const formatEvent = (event, components) => {
    const {
        documentName,
        externalPublisherId,
        documentTitle,
        refIdentifiers,
        signals,
        feetOfClayRatio: foc,
        clearSkiesStatus: similarity,
        gptDetectorScore: unnatural,
        retractionWatch,
        suspects
    } = event;
    const { extracted, invalid, parsed, unidentified } = refIdentifiers;
    const { retracted: fRetracted, details: fDetails } = foc || {};
    const { affiliations, identities, emails, tempmails, actors, articles, manuscripts, metadata, gptOutput } = suspects;

    let formatted = {
        header: {
            'External publisher id': documentName || externalPublisherId,
            'Document title': documentTitle,
        },
        doi: {
            'Total number of references listed in manuscript': '' + (parsed ?? '-'),
            'From which DOIs were listed or could be retrieved': '' + (extracted ?? '-'),
            'Number of DOIs in the reference list that do not exist (via check DOI.org)': invalid?.length && invalid.length > 0
                ? '' + invalid.length
                : '' + (invalid?.length ?? '-'),
            'Non-retrieved reference titles': unidentified?.length ? unidentified.map(item => item.title) : '-',
        },
        retractions: {
            'Retraction Watch:': !retractionWatch
                ? 'N/A'
                : retractionWatch?.length
                    ? retractionWatch.map(item => `${item.DOI} ${item.title}`)
                    : 'None found',
        },
        pubpeer: {
            'PubPeer retractions': !foc
                ? 'N/A'
                : fRetracted?.length
                    ? fRetracted
                    : 'None found',
            'PubPeer mentions:': !foc
                ? 'N/A'
                : fDetails?.length
                    ? fDetails.map(item => `${item.doi} [${item.comments}]`)
                    : 'None found',
        },
        similarity: {
            'Papermill similarity': similarity !== null
                ? !isEmptyObject(similarity)
                    ? capitalized(similarity)
                    : 'None'
                : 'N/A',
        },
        text: {
            'Tortured phrases': !signals
                ? 'N/A'
                : Array.isArray(signals) && signals.length
                    ? signals.map(item => item.pattern)
                    : 'Not found',
            'Unnatural text': isNumeric(unnatural) ? unnatural + '%' : 'N/A',
        },
        metadata: {
            ...(components.watchlistFakeAffiliationNames && {
                'Fake affiliation names': formatMetadataCategory(affiliations),
            }),
            ...(components.watchlistFakeNamesOrEmailAddresses && {
                'Fake names or e-mail addresses': formatMetadataCategory(identities),
            }),
            ...(components.watchlistFakeEmailDomains && {
                'Fake e-mail domains': formatMetadataCategory(emails),
            }),
            ...(components.watchlistDisposableEmailDomains && {
                'Disposable e-mail domains': formatMetadataCategory(tempmails),
            }),
            ...(components.watchlistBadActors && {
                'Bad actors': formatMetadataCategory(actors),
            }),
            ...(components.watchlistSuspectArticles && {
                'Suspect articles': formatMetadataCategory(articles),
            }),
            ...(components.watchlistManuscriptsOfferedForSale && {
                'Manuscripts offered for sale': formatMetadataCategory(manuscripts),
            }),
            ...(components.watchlistMetadataSuspects && {
                'Metadata suspects': formatMetadataCategory(metadata),
            }),
            ...(components.watchlistChatGPTOutput && {
                'GenAI response': formatMetadataCategory(gptOutput),
            })
        }
    };

    return formatted;
};

function formatMetadataCategory(items) {
    if (!items || !Array.isArray(items)) {
        return 'N/A';
    }

    if (!items?.length) {
        return 'None found';
    }

    return items.map(item => {
        if (isObject(item)) {
            const { value, providedby } = item;
            return providedby ? `${value}\nProvided by ${providedby}` : value;
        }
        return item;
    }).join('\n\n');
};

export const genReport = async (event, components) => {
    const doc = new jsPDF();
    let formatted = formatEvent(event, components);

    const autoTableStyle = {
        columnStyles: { 0: { cellWidth: 70 }, 1: { cellWidth: 'auto' } },
        bodyStyles: {
            valign: 'middle',
            fillColor: [236, 236, 236],
        },
        alternateRowStyles: {
            fillColor: [255, 255, 255]
        }
    }

    doc.setFontSize(16).text('Report', 15, 15);
    let body = tableBody(formatted.header);
    doc.autoTable({
        ...autoTableStyle,
        startY: 20,
        body
    });

    let finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('Reference analysis', 15, finalY + 10);
    finalY = doc.previousAutoTable.finalY;
    body = tableBody(formatted.doi);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    body = tableBody(formatted.retractions);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 3,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('PubPeer', 15, finalY + 10);
    body = tableBody(formatted.pubpeer);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('ClearSkies Papermill Alarm', 15, finalY + 10);
    body = tableBody(formatted.similarity);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('Text analysis', 15, finalY + 10);
    body = tableBody(formatted.text);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12).text('Watchlist', 15, finalY + 10);
    body = tableBody(formatted.metadata);
    doc.autoTable({
        ...autoTableStyle,
        startY: finalY + 15,
        body
    });

    const blob = new Blob([doc.output()], { type: 'application/pdf' })
    return blob;
};

const tableBody = obj => {
    let rows = Object.entries(obj);
    return rows.map(row => {
        if (Array.isArray(row[1])) {
            row[1] = row[1].join('\n');
        }
        row[1] = decode(row[1]);
        return row;
    });
};

const capitalized = word => word.charAt(0).toUpperCase() + word.slice(1);