import React from "react";

const Logo = () => {
  return (
    <div className="d-flex bg-primary text-white p-3">
      <h3 className="mb-0 fw-bold">STM Integrity Hub</h3>
    </div>
  );
};

export default Logo;
